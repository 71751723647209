import React, { useState, useEffect } from 'react'
import { Button, Col, Row, Table,Form} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import {
  DatatableWrapper,
  Pagination,
  TableBody,
  TableHeader
} from 'react-bs-datatable';  
import WhiteObjectApi from "../../api/WhiteObjectApi";
import moment from 'moment';
import Confirm from '../Confirm';
import FilesEdit from './FilesEdit';  
import FilesView from './FilesView';
import fileDownload from 'js-file-download';
import Image from 'react-bootstrap/Image'
import * as constants from '../../constants/CONSTANT';
import { render } from '@testing-library/react';
import Permissionhelper from "../common/Permissionhelper";
const RelatedListFiles = (props) => {
  console.log("PROPS",props)
  console.log("onFileUpdate_Main",props.onFileUpdate)
  
  const [modalShow, setModalShow] = React.useState(false);
  const [showRecord, setShowRecords] = React.useState(false);
  const [file, setFile] = React.useState('');
  const [downloads, setDownloads] = React.useState([]);
  const [modalShowFile, setModalShowFile] = React.useState(false);
  const [modalShowFileView, setModalShowFileView] = React.useState(false);
  const [folderName, setfolderName] = React.useState('');  //Added by shivani
  
  const [body, setBody] = useState([]);
  const MIMEType = new Map([
    ["text/csv", "csv"],
    ["application/msword", "doc"],
    ["application/vnd.openxmlformats-officedocument.wordprocessingml.document", "docx"],
    ["image/gif", "gif"],
    ["text/html", "html"],
    ["image/jpeg", "jpeg"],
    ["image/jpg", "jpg"],
    ["application/json", "json"],
    ["audio/mpeg", "mp3"],
    ["video/mp4", "mp4"],
    ["image/png", "png"],
    ["application/pdf", "pdf"],
    ["application/vnd.ms-powerpoint", "ppt"],
    ["application/vnd.openxmlformats-officedocument.presentationml.presentation", "pptx"],
    ["image/svg+xml", "svg"],
    ["text/plain", "txt"],
    ["application/vnd.ms-excel", "xls"],
    ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "xlsx"],
    ["text/xm", "xml"],
    ["application/xml", "xml"],
    ["application/atom+xml", "xml"],
    ["application/zip", "zip"],
    ]);

  useEffect(() => {
    filesList(folderName); //Added by shivani
   
    
  }, [props.refreshFileList]);

  const handleDelete = (row) => {
    setModalShow(true);
    setFile(row);
  }
  console.log("onFileUpdate",props.onFileUpdate)
  
  const labels = {
    beforeSelect: " "
  }

  const deleteFile = async () => {
    ////console.log('delete call', file.id)
    const result = await WhiteObjectApi.deleteFile(file.id);
    //console.log('delete successfully ',result)
    if (result.success) {
        const filterFiles = body.filter((item) => item.id !== file.id);
        setBody(filterFiles);
        setModalShow(false);
        props.submitFiles(); //Added by shivani 7 aug
    }
  }

  const downloadFile = async (row) => {
    //console.log('if file download call',row);
    const result = await WhiteObjectApi.downloadFiles(row.id,folderName);//FolderName to download 
    if(result){
      fileDownload(result, row.title);
    }
  
 }

   const submitFiles = () => {
    setModalShowFile(false);
    setShowRecords(Date.now());
    filesList(folderName); //Added by shivani
    console.log("props", props);
    props.submitFiles(); // Added By shivani

  }

  const editFile = (row) => {
    setModalShowFile(true)
    setFile(row);
  }

  const fileView = (row) => {
    setModalShowFileView(true)
    setFile(row);
  }

  const filesList = (foldername) => {
    async function init() {
      
      //console.log('variable:', props.parent);
      if(props.parent.id && foldername){
        let files = await WhiteObjectApi.fetchFiles(props.parent.id,foldername);
        console.log('FILES',files)
      if (files && files?.length > 0){
        let arr = [];
        
        for(let i=0; i < files.length ; i++){
          let file = files[i];
          if(file.filetype === 'jpg'
          || file.filetype === 'jpeg'
          || file.filetype === 'png'){

            //////console.log('fetch:', file.filetype);
            let result = await WhiteObjectApi.downloadFiles(file.id,foldername);
            //////console.log('result:', result);
            file['body'] = window.URL.createObjectURL(result);
        }else{
          file['body'] = '';
        }

          
          arr.push(file);
        }
   
        setBody([...arr]);

      
      }else{
        setBody([]);
      }
      }
      
    }
    init();
  }

  const handleFolderChange = (e) => {
    let foldername = e.target.value;
    setfolderName(foldername); //Added by shivani
    console.log("foldername",foldername)
    // if(props.table === "Product"){
    //   foldername = props.table;
    // }
    console.log("Final Folder",foldername)
    if(foldername) {
      console.log("foldername2",foldername)
      filesList(foldername)
    }
      
  }



  const fileSize = (bytes) =>  {
    var exp = (bytes / 1024)  / 1024;
    return exp.toFixed(2)+" MB";
  }

  const renderPreview = (type, body) => {
    //////console.log(type);
    switch(type) {
      case constants.PDF:
        return <i className='fa-solid fa-file-pdf fa-2xl file-thumbnail'></i>;
      case constants.DOC:
      case constants.DOCX:
        return <i className='fa-solid fa-file-word fa-2xl file-thumbnail'></i>;
      case constants.XLS:
      case constants.XLSX:
         return <i className='fa-solid fa-file-excel fa-2xl file-thumbnail'></i>;
      case constants.PNG:
      case constants.JPG:
      case constants.JPEG:
        //  return <Image src={body} thumbnail width="200px"/>
        return <Image src={body}  thumbnail style={{ width: '200px', height: '-webkit-fill-available' }}/>
      default:
        return <i className='fa-solid fa-file-file fa-2xl file-thumbnail'></i>;
    }
  }


  
  // Added by shivani start
  const header = [
    {
      title: 'Title', prop: 'title',
      cell: (row) => (
        <div className="text-truncate" style={{ maxWidth: '250px' }}>
          <a href='javascript:void(0)' onClick={() => fileView({ row })} className="d-block">
            {row.title}
          </a>
        </div>
      )
    },
    { title: '', prop: 'body', cell: (row) => (
      <div className="text-center" style={{ height: '50px', width: '50px' }}>
        <a href='javascript:void(0)' onClick={() => downloadFile(row)}>
          {renderPreview(row.filetype, row.body)}
        </a>
      </div>
    )},
    { title: 'File Type', prop: 'filetype', cell: (row) => row.filetype },
    { title: 'File Size', prop: 'filesize', cell: (row) => fileSize(row.filesize) },
    { title: 'Folder Name', prop: 'file_path' },
    { title: 'Lead/Project', prop: 'name' },
    {
      title: 'Primary Image', prop: 'primary_image',
      cell: (row) => (
        <div>{row.primary_image ? 'Active' : 'Inactive'}</div>
      )
    },
    { title: 'Quote Status', prop: 'quote_status' },
    { title: 'Created Date', prop: 'createddate', cell: (row) => moment(row.createddate).format('DD-MM-YYYY') },
    {
      title: 'Actions', prop: 'id', cell: (row) => (
        <div className="d-flex justify-content-center align-items-center">
          <Button className='btn-sm mx-1' onClick={() => editFile({ row })}><i className="fa-regular fa-pen-to-square"></i></Button>
          {Permissionhelper.checkPermission(constants.SUPER_ADMIN) && 
                  <Button className='btn-sm mx-1' variant='danger' onClick={() => handleDelete(row)}><i className="fa-regular fa-trash-can"></i></Button>  
                  }
          
          <Button className='btn-sm mx-1' variant='danger' onClick={() => downloadFile(row)}><i className="fa-solid fa-download"></i></Button>
        </div>
      )
    }
  ];
  // Added by shivani end


  return (
    <>
      {modalShow &&
      <Confirm
        show={modalShow}
        onHide={() => setModalShow(false)}
        deleteFile={deleteFile}
        title="Confirm delete?"
        message="You are going to delete the record. Are you sure?"
        table="file"
      />}
     {modalShowFile &&
        <FilesEdit
          show={modalShowFile}
          onFileUpdate={props.onFileUpdate}
          onHide={() => setModalShowFile(false)}
          parent={props.parent}
          file={file}
          table={props.table}
          submitFiles={submitFiles}
          // parentsubmitFiles={props.submitFiles} //Added by shivani
        />
        }
        {modalShowFileView &&
        <FilesView
          show={modalShowFileView}
          onHide={() => setModalShowFileView(false)}
          file={file}
        />
        }
      {body ?



        <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
          initialState: {
            rowsPerPage: 5
          }
        }}>
          <Row className="mb-4">
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            >
            </Col>
            
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            >
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
            </Col>
            {/* {props.table !== "Product" && ( */}
              <Col md={4}>
              <Form.Group>
                      <Form.Label className="" htmlFor="formFolderName">
                          Folder Name
                      </Form.Label>
                      <Form.Select aria-label="Select Folder" value={folderName} name="folder_name" onChange={handleFolderChange}>
                          <option value="">--Select Folder--</option>
                          <option value="Quotation">Quotation</option>
                          <option value="Measurement">Measurement</option>
                          <option value="WorkCompletionCertificate">Work Completion Certificate</option>
                          <option value="ProductImages">Product Images</option>
                          <option value="ContactImages">Contact Images</option>
                          <option value="Check-In-Documents">Check-In-Documents</option>
                          <option value="RepairTicket">Repair Ticket</option>
                      </Form.Select>
                  </Form.Group>
              </Col>
            {/* )} */}
            
            
          </Row>
          <Table striped className="related-list-table">
            <TableHeader />
            <TableBody />
          </Table>
          {body.length > 0 && <Pagination />}    {/* //Added by shivani */}
        
        </DatatableWrapper> : ''}
        <p style={{color:"red"}}>
        {downloads?.length > 0 ?  downloads.map((row, index) => ( 
            
            <img src={row} alt='' />
            
        )) : ''}  
        </p>
    </>

    
  )
}

export default RelatedListFiles